<template>
    <div>
        <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-v1 px-2">
                    <div class="auth-inner py-2">
                        <!-- Login v1 -->
                        <div class="card mb-0">
                            <div class="card-body">
                                <a href="#" class="brand-logo">
                                    <!-- <svg viewbox="0 0 139 95" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="28">
                                        <defs>
                                            <lineargradient id="linearGradient-1" x1="100%" y1="10.5120544%" x2="50%" y2="89.4879456%">
                                                <stop stop-color="#000000" offset="0%"></stop>
                                                <stop stop-color="#FFFFFF" offset="100%"></stop>
                                            </lineargradient>
                                            <lineargradient id="linearGradient-2" x1="64.0437835%" y1="46.3276743%" x2="37.373316%" y2="100%">
                                                <stop stop-color="#EEEEEE" stop-opacity="0" offset="0%"></stop>
                                                <stop stop-color="#FFFFFF" offset="100%"></stop>
                                            </lineargradient>
                                        </defs>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g id="Artboard" transform="translate(-400.000000, -178.000000)">
                                                <g id="Group" transform="translate(400.000000, 178.000000)">
                                                    <path class="text-primary" id="Path" d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z" style="fill: currentColor"></path>
                                                    <path id="Path1" d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z" fill="url(#linearGradient-1)" opacity="0.2"></path>
                                                    <polygon id="Path-2" fill="#000000" opacity="0.049999997" points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"></polygon>
                                                    <polygon id="Path-21" fill="#000000" opacity="0.099999994" points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"></polygon>
                                                    <polygon id="Path-3" fill="url(#linearGradient-2)" opacity="0.099999994" points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"></polygon>
                                                </g>
                                            </g>
                                        </g>
                                    </svg> -->
                                    <img :src="right_single_title" alt="" class="">
                                    <!-- <h2 class="brand-text text-primary ms-1">RIO COLINA</h2> -->
                                </a>

                                <h4 class="card-title mb-1 text-center">Welcome to Rio Colina! 👋</h4>

                                <form class="auth-login-form mt-2" method="post" @submit.prevent="submitForm">
                                    <div class="mb-1">
                                        <label for="login-email" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="email" v-model="email" name="email" placeholder="john@example.com" aria-describedby="login-email" tabindex="1" autofocus
                                        @keyup=" email.length > 0 ? [(emailError = 'none'), (this.disabled = false)] : (emailError = 'block') " />
                                        <div class="invalid-feedback fw-bold fst-italic" :style="{display: emailError}"> Please enter Email. </div>
                                    </div>

                                    <div class="mb-1">
                                        <div class="d-flex justify-content-between">
                                            <label class="form-label" for="login-password">Password</label>
                                            <a href="">
                                                <small>Forgot Password?</small>
                                            </a>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input type="password" class="form-control form-control-merge" v-model="password" id="password" name="password" tabindex="2" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="login-password"
                                            @keyup=" password.length > 0 ? [(passwordError = 'none'), (this.disabled = false)] : (passwordError = 'block') " />
                                            <span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span>
                                            <div class="invalid-feedback fw-bold fst-italic" :style="{display: passwordError}"> Please enter Password. </div>
                                        </div>
                                    </div>
                                    <div class="mb-1">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="remember-me" tabindex="3" name="remember" v-model="remember"/>
                                            <label class="form-check-label" for="remember-me"> Remember Me </label>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary w-100" tabindex="4" :disabled="disabled">Sign in</button>
                                </form>
                            </div>
                        </div>
                        <!-- /Login v1 -->
                    </div>
                </div>

            </div>
    </div>
</template>

<script>
import axios from "axios";
import right_single_title from '../../assets/images/header_logo.png';

export default {
    name: 'Login',
    data() {
        return {
            email: "",
            password: "",
            emailError: "",
            passwordError: "",
            remember: false,
            response: "",
            disabled: false,
            right_single_title: right_single_title
        };
    },
    mounted() {
        if(localStorage.getItem('auth_token')) {
            this.$router.push({name: 'dashboard'});
        }
    },
    methods: {
        async submitForm() {
            this.disabled = true;
            if (this.email.length === 0) {
                this.emailError = "block";
            } else if (this.password.length === 0) {
                this.passwordError = "block";
            } else {
                this.disabled = false;
                await axios.post(process.env.VUE_APP_API_URL+"/login",
                    {
                        email: this.email,
                        password: this.password,
                        remember: this.remember,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Accept': "application/json",
                        },
                    }
                ).then((response) => {
                    this.disabled = false;
                    if (response.data.status) {
                        this.$toast.success(response.data.message);
                        localStorage.setItem('auth_token', response.data.data.token)
                        localStorage.setItem('auth_name', response.data.data.name)
                        this.$router.go('/dashboard')
                    } else {
                        if(response.data.error?.[0] === undefined) {
                            this.$toast.error(response.data.message);
                        } else {
                            this.$toast.error(response.data.message);
                        }
                    }
                }).catch((error) => {
                    this.$toast.error(error);
                    this.disabled = false;
                });
            }
        },
    }
}
</script>
<style>
    
</style>