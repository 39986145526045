function lazyLoad(path, view) {
  return () => import(`@/views/${path}${view}.vue`);
}

const routes = [
  {
    path: "/login",
    name: "Login",
    component: lazyLoad("Auth/", "Login"),
    display: "Login",
    icon: "home",
    link: false,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: lazyLoad("", "Dashboard"),
    display: "Dashboard",
    icon: "home",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/villa-assign/:booking_id",
    name: "villa-assign",
    component: lazyLoad("", "VillaAssign"),
    display: "Event",
    icon: "trello",
    link: false,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "",
    redirect: "/dashboard",
    name: "redirectToDashboard",
    display: "redirectToDashboard",
    icon: "target",
    link: false,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master",
    name: "master",
    display: "Master",
    icon: "target",
    link: false,
    child: true,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/department",
        name: "department",
        component: lazyLoad("Master/", "Department"),
        display: "Department",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/designation",
        name: "designation",
        component: lazyLoad("Master/", "Designation"),
        display: "Designation",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/employee",
        name: "employee",
        component: lazyLoad("Master/", "Employee"),
        display: "Employee",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/villa-type",
        name: "villa-type",
        component: lazyLoad("Master/", "VillaType"),
        display: "Villa Type",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/villa-facility",
        name: "villa-facility",
        component: lazyLoad("Master/", "VillaFacility"),
        display: "Villa Facility",
        icon: "circle",
        link: false,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/villa",
        name: "villa",
        component: lazyLoad("Master/", "Villa"),
        display: "Villa",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/amenity",
        name: "amenity",
        component: lazyLoad("Master/", "Amenity"),
        display: "Amenity",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/picnic-package-include",
        name: "picnic-package-include",
        component: lazyLoad("Master/", "PicnicPackageInclude"),
        display: "Picnic Package Include",
        icon: "circle",
        link: false,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/picnic-package-amenity",
        name: "picnic-package-amenity",
        component: lazyLoad("Master/", "PicnicPackageAmenity"),
        display: "Picnic Package Amenity",
        icon: "circle",
        link: false,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/festival",
        name: "festival",
        component: lazyLoad("Master/", "Festival"),
        display: "Festival",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/theater",
        name: "theater",
        component: lazyLoad("Master/", "Theater"),
        display: "Theater",
        icon: "circle",
        link: false,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/members",
    name: "members",
    component: lazyLoad("Member/", "Registration"),
    display: "Members",
    icon: "user-plus",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "user",
    component: lazyLoad("Master/", "User"),
    display: "User",
    icon: "users",  
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/picnic-package",
    name: "picnic-package",
    component: lazyLoad("Master/", "PicnicPackage"),
    display: "Picnic",
    icon: "sun",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/event",
    name: "event",
    component: lazyLoad("Events/", "Event"),
    display: "Event",
    icon: "trello",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking",
    name: "booking",
    component: lazyLoad("Booking/", "BookingList"),
    display: "Booking",
    icon: "shopping-bag",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking-trash",
    name: "booking-trash",
    component: lazyLoad("Booking/", "BookingTrash"),
    display: "Booking Trash",
    icon: "trash-2",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking/villa",
    name: "booking.villa",
    component: lazyLoad("Booking/", "BookingVilla"),
    display: "Booking1",
    icon: "circle",
    link: false,
    show: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking/villa-owner",
    name: "booking.villa.owner",
    component: lazyLoad("Booking/", "BookingVillaOwner"),
    display: "Booking For Owner",
    icon: "shopping-bag",
    link: true,
    show: true,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/availability",
    name: "availability",
    component: lazyLoad("Setting/", "Availability"),
    display: "Booking Availability",
    icon: "calendar",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking/picnic",
    name: "booking.picnic",
    component: lazyLoad("Booking/", "BookingOneDayPicnic"),
    display: "Booking2",
    icon: "circle",
    link: false,
    show: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking/event",
    name: "booking.event",
    component: lazyLoad("Booking/", "BookingEvent"),
    display: "Booking3",
    icon: "circle",
    link: false,
    show: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "",
    name: "gallery",
    display: "Gallery",
    icon: "image",
    link: false,
    child: true,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/image",
        name: "image",
        component: lazyLoad("Gallery/", "Image"),
        display: "Image",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/video",
        name: "video",
        component: lazyLoad("Gallery/", "Video"),
        display: "Video",
        icon: "circle",
        link: true,
        className: " nav-item",
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/blog",
    name: "blog",
    component: lazyLoad("Blog/", "Blog"),
    display: "Blog",
    icon: "package",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/enquiry",
    name: "enquiry",
    component: lazyLoad("", "Enquiry"),
    display: "Enquiry",
    icon: "clipboard",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/gate-bookings",
    name: "gateBookings",
    component: lazyLoad("", "GateBooking"),
    display: "Appointments",
    icon: "clipboard",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/event-incoming/:event_id",
    name: "event-incoming",
    component: lazyLoad("Events/", "EventIncoming"),
    display: "Event",
    icon: "trello",
    link: false,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/coupon",
    name: "coupon",
    component: lazyLoad("Booking/", "Coupon"),
    display: "Coupon",
    icon: "tag",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: lazyLoad("Setting/", "Setting"),
    display: "Setting",
    icon: "settings",
    link: true,
    child: false,
    className: " nav-item",
    meta: {
      requiresAuth: true,
    },
  },
  
];

export default routes;
