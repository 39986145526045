<script setup>
import Header from "./components/master/Header.vue";
import Sidebar from "./components/master/Sidebar.vue";
import Login from "./views/Auth/Login.vue";

const token = localStorage.getItem('auth_token');

// console.log(import.meta.env.VITE_BASE_URL);
</script>

<template>
    <div
    class="vertical-layout vertical-menu-modern navbar-floating footer-static"
    data-open="click"
    data-menu="vertical-menu-modern"
    :data-col="token ? `` : `blank-page` "
    >
    <template v-if="token">
      <Header />
      <Sidebar />

      <!-- BEGIN: Content-->
      <div class="app-content content ecommerce-application">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
          <router-view></router-view>
        </div>
      </div>
      <!-- END: Content-->
    </template>
    <template v-else>
      <Login /> 
    </template>
  </div>
</template>

<style>
.sr-only {
  display: none !important;
}
.pagination {
  float: right !important;
  margin-top: 10px;
}
.showing_entry {
  margin-top: 10px;
}
@media (min-width: 576px) {
  .modal-slide-in .modal-dialog {
      width: 50rem;
  }
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-left: -10px;
    margin-top: 2px;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b, .select2-container--default .select2-selection--single .select2-selection__arrow b {
  padding-right: 0;
}
.vue-feather--search {
  color: #6e6b7b;
  width: 14px;
  height: 14px;
}
.nav-item  a .vue-feather {
  margin-top: -4px;
}
.nav-item  .nav-link .vue-feather {
  margin-top: 0;
}
.dropzone {
  border: 2px dashed #7367f0;
  background: #f8f8f8;
  position: relative;
}
.dropzone .dz-message {
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #7DA741;
  /* color: #7367f0; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.vue-dropzone > .dz-preview .dz-details {
  background-color: rgb(115 103 240 / 50%);
}
.dz-remove {
  border: 0 !important;
  text-shadow: 0px 1px 5px red;
}
.input-group-text {
  border: 1px solid #d8d6de;
}
#app {
  position: relative;
}
.loader {
  position: absolute;
  z-index: 9999;
  left: 40%;
  top: 100%;
}
.modal-content .btn-close {
  width: 15px;
  color: black;
  padding: 10px !important;
  height: 15px;
  opacity: 1;
  font-size: 26px;
  box-shadow: 2px 5px 13px 0px #b6b6b6;
}
.dropzone .dz-preview.dz-error .dz-error-message {
    display: none !important;
}
.dp__select {
    border-color: #7DA741 !important;
    background-color: #7DA741 !important;
    border: 1px solid #7DA741 !important;
    /* border-color: #7367f0 !important;
    background-color: #7367f0 !important;
    border: 1px solid #7367f0 !important; */
    color: #fff !important;
  }
  .dp__select:hover {
    box-shadow: 0 8px 25px -8px #7DA741;
    /* box-shadow: 0 8px 25px -8px #7367f0; */
  }
  .dp__cancel {
    border: 1px solid #82868b !important;
    background-color: transparent;
    color: #82868b;
    margin-right: 10px;
  }
  .dp__cancel:hover {
    background-color: rgba(130, 134, 139, 0.04);
    color: #82868b;
  }
  .dp__today {
    border-color: #7DA741 !important;
    /* border-color: #7367f0 !important; */
  }
  .dp__range_end, .dp__range_start, .dp__active_date {
    background-color: #7DA741 !important;
    /* background-color: #7367f0 !important; */
  } 
  .dp__action_row, .dp__menu {
    border-radius: 10px;
    border: none;
    box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
  }
  .dp__range_between {
    background: #e3e0ff;
    border-bottom: 1px solid #e3e0ff;
  }
  .dp__button {
    display: none !important;
  }
  .menu-content {
    margin-left: 15px !important;
  }
  .c-toast--warning {
    color: #000 !important;
  }
</style>
