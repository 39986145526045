import { createRouter, createWebHistory } from "vue-router";
import routes from "./components/commonConstant/RoutesConstant.js";

const route = [
  { path: "*", redirect: "/" },
];
const router = createRouter({
  history: createWebHistory(),
  route,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("auth_token");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
export default router;
