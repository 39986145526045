import { createStore } from "vuex";
import axios from "axios";

const store = createStore({
  state() {
    return {
      departmentData: {},
      designationData: {},
      villaTypeData: {},
      villaData: {},
      picnicPackageData: {},
      amenityData: {},
      galleryImageTypeData: {},
      paymentModeData: {},
      entryExitIpPortData: {},
      sidebarMenu: false,
    };
  },
  mutations: {
    updateDepartmentList(state, departmentData) {
      state.departmentData = departmentData;
    },
    updateDesignationList(state, designationData) {
      state.designationData = designationData;
    },
    updateVillaTypeList(state, villaTypeData) {
      state.villaTypeData = villaTypeData;
    },
    updateVillaList(state, villaData) {
      state.villaData = villaData;
    },
    updatePicnicPackageList(state, picnicPackageData) {
      state.picnicPackageData = picnicPackageData;
    },
    updateAmenityList(state, amenityData) {
      state.amenityData = amenityData;
    },
    updateGalleryImageTypeList(state, galleryImageTypeData) {
      state.galleryImageTypeData = galleryImageTypeData;
    },
    updatePaymentModeList(state, paymentModeData) {
      state.paymentModeData = paymentModeData;
    },
    updateEntryExitIpPortList(state, entryExitIpPortData) {
      state.entryExitIpPortData = entryExitIpPortData;
    },
    updateSidebarMenu(state, status) {
      state.sidebarMenu = status;
    },
  },
  actions: {
    setSidebarMenu({ commit }, status) {
      commit("updateSidebarMenu", status);
    },
    async getDepartmentList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/department_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          commit("updateDepartmentList", response.data.data);
        });
    },
    async getDesignationList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/designation_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateDesignationList", response.data.data);
        });
    },
    async getVillaTypeList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/villa_type_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateVillaTypeList", response.data.data);
        });
    },
    async getVillaList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/villa_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateVillaList", response.data.data);
        });
    },
    async getPicnicPackageList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/picnic_package_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updatePicnicPackageList", response.data.data);
        });
    },
    async getAmenityList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/amenity_master?page=" + 0, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateAmenityList", response.data.data);
        });
    },
    async getGalleryImageTypeList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/gallery_image_type", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateGalleryImageTypeList", response.data.data);
        });
    },
    async getPaymentModeList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/booking/payment-mode-list", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updatePaymentModeList", response.data.data);
        });
    },
    async getEntryExitIpPortList({ commit }) {
      await axios
        .get(process.env.VUE_APP_API_URL + "/entry-exit-name", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((response) => {
          commit("updateEntryExitIpPortList", response.data.data);
        });
    },
  },
  getters: {
    sidebarMenu: (state) => state.sidebarMenu,
  },
});

export default store;
