<template>
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
    :class="{ showSideBar: sidebarStatus }"
  >
    <div class="navbar-header expanded">
      <a class="close" href="#" @click="closeToggleMenu()"
        ><vue-feather type="x"></vue-feather
      ></a>
      <img src="../../assets/header_logo.png" alt="" class="" />
      <ul class="nav navbar-nav flex-row">
        <!-- <li class="nav-item me-auto"> -->
        <!-- <router-link to="dashboard" class="navbar-brand"> -->
        <!-- <span class="brand-logo"> -->
        <!-- <svg viewbox="0 0 139 95" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="24" width="24">
                                <defs>
                                    <lineargradient id="linearGradient-1" x1="100%" y1="10.5120544%" x2="50%" y2="89.4879456%">
                                        <stop stop-color="#000000" offset="0%"></stop>
                                        <stop stop-color="#FFFFFF" offset="100%"></stop>
                                    </lineargradient>
                                    <lineargradient id="linearGradient-2" x1="64.0437835%" y1="46.3276743%" x2="37.373316%" y2="100%">
                                        <stop stop-color="#EEEEEE" stop-opacity="0" offset="0%"></stop>
                                        <stop stop-color="#FFFFFF" offset="100%"></stop>
                                    </lineargradient>
                                </defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="Artboard" transform="translate(-400.000000, -178.000000)">
                                        <g id="Group" transform="translate(400.000000, 178.000000)">
                                            <path class="text-primary" id="Path" d="M-5.68434189e-14,2.84217094e-14 L39.1816085,2.84217094e-14 L69.3453773,32.2519224 L101.428699,2.84217094e-14 L138.784583,2.84217094e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L6.71554594,44.4188507 C2.46876683,39.9813776 0.345377275,35.1089553 0.345377275,29.8015838 C0.345377275,24.4942122 0.230251516,14.560351 -5.68434189e-14,2.84217094e-14 Z" style="fill:currentColor"></path>
                                            <path id="Path1" d="M69.3453773,32.2519224 L101.428699,1.42108547e-14 L138.784583,1.42108547e-14 L138.784199,29.8015838 C137.958931,37.3510206 135.784352,42.5567762 132.260463,45.4188507 C128.736573,48.2809251 112.33867,64.5239941 83.0667527,94.1480575 L56.2750821,94.1480575 L32.8435758,70.5039241 L69.3453773,32.2519224 Z" fill="url(#linearGradient-1)" opacity="0.2"></path>
                                            <polygon id="Path-2" fill="#000000" opacity="0.049999997" points="69.3922914 32.4202615 32.8435758 70.5039241 54.0490008 16.1851325"></polygon>
                                            <polygon id="Path-21" fill="#000000" opacity="0.099999994" points="69.3922914 32.4202615 32.8435758 70.5039241 58.3683556 20.7402338"></polygon>
                                            <polygon id="Path-3" fill="url(#linearGradient-2)" opacity="0.099999994" points="101.428699 0 83.0667527 94.1480575 130.378721 47.0740288"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg> -->
        <!-- <img :src="right_single_title" alt="" class="">
                        </span>
                        <h2 class="brand-text">RIO COLINA</h2> -->
        <!-- </router-link> -->
        <!-- </li> -->
        <!-- <li class="nav-item nav-toggle">
                    <a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse">
                        <i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i>
                        <i class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary" data-feather="disc" data-ticon="disc"></i>
                    </a>
                </li> -->
      </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
        <template v-for="route in routes" :key="route.path">
          <li
            :class="[
              route.className,
              ownSegment === route.name ? 'active' : '',
            ]"
            v-if="route.link"
            @click="list(route.name, route.link)"
          >
            <router-link
              :to="route.child ? '#' : route.path"
              class="d-flex align-items-center"
            >
              <vue-feather :type="route.icon"></vue-feather>
              <span
                class="menu-title text-truncate"
                :data-i18n="route.display"
                >{{ route.display }}</span
              >
            </router-link>
          </li>

          <li
            :class="[
              route.className,
              route.child ? `has-sub` : ``,
              currentDD == route.name && isOpen == true ? `open` : ``,
              master.includes(ownSegment) && isOpen == true
                ? 'sidebar-group-active open'
                : '',
            ]"
            @click="ddOpen(route.name)"
            v-else-if="route.name === 'master'"
          >
            <router-link
              :to="route.child ? '' : route.path"
              class="d-flex align-items-center"
            >
              <vue-feather :type="route.icon"></vue-feather>
              <span
                class="menu-title text-truncate"
                :data-i18n="route.display"
                >{{ route.display }}</span
              >
            </router-link>
            <ul class="menu-content" v-show="route.child">
              <template v-for="child in route.children" :key="child.path">
                <li
                  :class="[ownSegment === child.name ? 'active' : '']"
                  @click="list(child.name, route.link), ddOpen(route.name)"
                  v-if="child.link"
                >
                  <router-link
                    :to="child.path"
                    class="d-flex align-items-center"
                  >
                    <vue-feather :type="child.icon"></vue-feather>
                    <span
                      class="menu-title text-truncate"
                      :data-i18n="child.display"
                      >{{ child.display }}</span
                    >
                  </router-link>
                </li>
              </template>
            </ul>
          </li>
          <li
            :class="[
              route.className,
              route.child ? `has-sub` : ``,
              currentDD == route.name && isGalleryOpen == true ? `open` : ``,
              gallery.includes(ownSegment) && isGalleryOpen == true
                ? 'sidebar-group-active open'
                : '',
            ]"
            @click="ddGalleryOpen(route.name)"
            v-if="route.name === 'gallery'"
          >
            <router-link
              :to="route.child ? '' : route.path"
              class="d-flex align-items-center"
            >
              <vue-feather :type="route.icon"></vue-feather>
              <span
                class="menu-title text-truncate"
                :data-i18n="route.display"
                >{{ route.display }}</span
              >
            </router-link>
            <ul class="menu-content" v-show="route.child">
              <template v-for="child in route.children" :key="child.path">
                <li
                  :class="[ownSegment === child.name ? 'active' : '']"
                  @click="
                    galleryList(child.name, route.link),
                      ddGalleryOpen(route.name)
                  "
                >
                  <router-link
                    :to="child.path"
                    class="d-flex align-items-center"
                  >
                    <vue-feather :type="child.icon"></vue-feather>
                    <span
                      class="menu-title text-truncate"
                      :data-i18n="child.display"
                      >{{ child.display }}</span
                    >
                  </router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import routes from "./../commonConstant/RoutesConstant.js";
import right_single_title from "../../assets/images/right_single_title.png";

export default {
  name: "Sidebar",
  data() {
    return {
      segment: null,
      ownSegment: null,
      routes: routes,
      isOpen: true,
      isGalleryOpen: true,
      currentDD: "",
      master: [
        "department",
        "designation",
        "employee",
        "villa-type",
        "villa-facility",
        "villa",
        "amenity",
        "picnic-package-include",
        "picnic-package-amenity",
        "festival",
        "theater",
      ],
      gallery: ["image", "video"],
      booking: ["villa"],
      right_single_title: right_single_title,
    };
  },
  mounted() {
    this.ownSegment = new URL(window.location.href).pathname
      .split("/")
      .filter(Boolean)[0];
  },
  computed: {
    sidebarStatus() {
      return this.$store.getters.sidebarMenu;
    },
  },
  methods: {
    closeToggleMenu() {
      this.$store.dispatch("setSidebarMenu", false);
    },
    list(name, link) {
      this.ownSegment = name;
      if (link) {
        this.isOpen = false;
        this.isGalleryOpen = false;
      }
    },
    ddOpen(name) {
      this.isOpen = !this.isOpen;
      this.isGalleryOpen = false;
      this.isBookingOpen = false;
      this.currentDD = name;
    },
    galleryList(name, link) {
      this.ownSegment = name;
      if (link) {
        this.isGalleryOpen = false;
        this.isOpen = true;
      }
    },
    ddGalleryOpen(name) {
      this.isOpen = false;
      this.isGalleryOpen = !this.isGalleryOpen;
      this.currentDD = name;
    },
  },
};
</script>

<style>
.main-menu .navigation li.has-sub > a:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 14px;
  transform: rotate(0deg);
  transition: all 0.2s ease-out;
}

li.has-sub.open:not(.menu-item-closing) > a:after {
  transform: rotate(90deg);
}

.main-menu .navbar-header {
  height: 9.45rem;
}

.showSideBar {
  width: 260px !important;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
