<template>
  <nav
    class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow container-xxl"
  >
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center">
        <ul class="nav navbar-nav d-xl-none" @click="setToggleMenu()">
          <li class="nav-item">
            <a class="nav-link menu-toggle" href="#"
              ><vue-feather type="menu"></vue-feather
            ></a>
          </li>
        </ul>
        <!-- <ul class="nav navbar-nav bookmark-icons">
          <li class="nav-item d-none d-lg-block">
            <a
              class="nav-link"
              href="app-email.html"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Email"
              ><vue-feather class="ficon" type="mail"></vue-feather
            ></a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a
              class="nav-link"
              href="app-chat.html"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Chat"
              ><vue-feather class="ficon" type="message-square"></vue-feather
            ></a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a
              class="nav-link"
              href="app-calendar.html"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Calendar"
              ><vue-feather class="ficon" type="calendar"></vue-feather
            ></a>
          </li>
          <li class="nav-item d-none d-lg-block">
            <a
              class="nav-link"
              href="app-todo.html"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Todo"
              ><vue-feather class="ficon" type="check-square"></vue-feather
            ></a>
          </li>
        </ul>
        <ul class="nav navbar-nav">
          <li class="nav-item d-none d-lg-block">
            <a class="nav-link bookmark-star"
              ><vue-feather class="ficon text-warning" type="star"></vue-feather
            ></a>
            <div class="bookmark-input search-input">
              <div class="bookmark-input-icon">
                <vue-feather type="search"></vue-feather>
              </div>
              <input
                class="form-control input"
                type="text"
                placeholder="Bookmark"
                tabindex="0"
                data-search="search"
              />
              <ul class="search-list search-list-bookmark"></ul>
            </div>
          </li>
        </ul> -->
      </div>
      <ul class="nav navbar-nav align-items-center ms-auto">
        <!-- <li class="nav-item dropdown dropdown-language"><a class="nav-link dropdown-toggle" id="dropdown-flag" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="flag-icon flag-icon-us"></i><span class="selected-language">English</span></a>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-flag"><a class="dropdown-item" href="#" data-language="en"><i class="flag-icon flag-icon-us"></i> English</a><a class="dropdown-item" href="#" data-language="fr"><i class="flag-icon flag-icon-fr"></i> French</a><a class="dropdown-item" href="#" data-language="de"><i class="flag-icon flag-icon-de"></i> German</a><a class="dropdown-item" href="#" data-language="pt"><i class="flag-icon flag-icon-pt"></i> Portuguese</a></div>
                </li> -->
        <!-- <li class="nav-item d-none d-lg-block"><a class="nav-link nav-link-style"><vue-feather class="ficon"
                            type="moon"></vue-feather></a></li> -->
        <!-- <li class="nav-item nav-search"><a class="nav-link nav-link-search"><vue-feather class="ficon"
                            type="search"></vue-feather></a>
                    <div class="search-input">
                        <div class="search-input-icon"><vue-feather type="search"></vue-feather></div>
                        <input class="form-control input" type="text" placeholder="Explore Vuexy..." tabindex="-1"
                            data-search="search">
                        <div class="search-input-close"><vue-feather vue-feather="x"></vue-feather></div>
                        <ul class="search-list search-list-main"></ul>
                    </div>
                </li> -->
        <!-- <li class="nav-item dropdown dropdown-cart me-25">
          <a class="nav-link" href="#" data-bs-toggle="dropdown"
            ><vue-feather type="shopping-cart"></vue-feather
            ><span
              class="badge rounded-pill bg-primary badge-up cart-item-count"
              >6</span
            ></a
          >
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 me-auto">My Cart</h4>
                <div class="badge rounded-pill badge-light-primary">
                  4 Items
                </div>
              </div>
            </li>
            <li class="scrollable-container media-list">
              <div class="list-item align-items-center">
                <img class="d-block rounded me-1" src="../../../app-assets/images/pages/eCommerce/1.png" alt="donuts" width="62">
                <div class="list-item-body flex-grow-1">
                  <vue-feather
                    class="ficon cart-item-remove"
                    type="x"
                  ></vue-feather>
                  <div class="media-heading">
                    <h6 class="cart-item-title">
                      <a class="text-body" href="app-ecommerce-details.html">
                        Apple watch 5</a
                      >
                    </h6>
                    <small class="cart-item-by">By Apple</small>
                  </div>
                  <div class="cart-item-qty">
                    <div class="input-group">
                      <input class="touchspin-cart" type="number" value="1" />
                    </div>
                  </div>
                  <h5 class="cart-item-price">$374.90</h5>
                </div>
              </div>
              <div class="list-item align-items-center">
                <img class="d-block rounded me-1" src="../../../app-assets/images/pages/eCommerce/7.png" alt="donuts" width="62">
                <div class="list-item-body flex-grow-1">
                  <vue-feather
                    class="ficon cart-item-remove"
                    type="x"
                  ></vue-feather>
                  <div class="media-heading">
                    <h6 class="cart-item-title">
                      <a class="text-body" href="app-ecommerce-details.html">
                        Google Home Mini</a
                      >
                    </h6>
                    <small class="cart-item-by">By Google</small>
                  </div>
                  <div class="cart-item-qty">
                    <div class="input-group">
                      <input class="touchspin-cart" type="number" value="3" />
                    </div>
                  </div>
                  <h5 class="cart-item-price">$129.40</h5>
                </div>
              </div>
              <div class="list-item align-items-center">
                <img class="d-block rounded me-1" src="../../../app-assets/images/pages/eCommerce/2.png" alt="donuts" width="62">
                <div class="list-item-body flex-grow-1">
                  <vue-feather
                    class="ficon cart-item-remove"
                    type="x"
                  ></vue-feather>
                  <div class="media-heading">
                    <h6 class="cart-item-title">
                      <a class="text-body" href="app-ecommerce-details.html">
                        iPhone 11 Pro</a
                      >
                    </h6>
                    <small class="cart-item-by">By Apple</small>
                  </div>
                  <div class="cart-item-qty">
                    <div class="input-group">
                      <input class="touchspin-cart" type="number" value="2" />
                    </div>
                  </div>
                  <h5 class="cart-item-price">$699.00</h5>
                </div>
              </div>
              <div class="list-item align-items-center">
                <img class="d-block rounded me-1" src="../../../app-assets/images/pages/eCommerce/3.png" alt="donuts" width="62">
                <div class="list-item-body flex-grow-1">
                  <vue-feather
                    class="ficon cart-item-remove"
                    type="x"
                  ></vue-feather>
                  <div class="media-heading">
                    <h6 class="cart-item-title">
                      <a class="text-body" href="app-ecommerce-details.html">
                        iMac Pro</a
                      >
                    </h6>
                    <small class="cart-item-by">By Apple</small>
                  </div>
                  <div class="cart-item-qty">
                    <div class="input-group">
                      <input class="touchspin-cart" type="number" value="1" />
                    </div>
                  </div>
                  <h5 class="cart-item-price">$4,999.00</h5>
                </div>
              </div>
              <div class="list-item align-items-center">
                <img class="d-block rounded me-1" src="../../../app-assets/images/pages/eCommerce/5.png" alt="donuts" width="62">
                <div class="list-item-body flex-grow-1">
                  <vue-feather
                    class="ficon cart-item-remove"
                    type="x"
                  ></vue-feather>
                  <div class="media-heading">
                    <h6 class="cart-item-title">
                      <a class="text-body" href="app-ecommerce-details.html">
                        MacBook Pro</a
                      >
                    </h6>
                    <small class="cart-item-by">By Apple</small>
                  </div>
                  <div class="cart-item-qty">
                    <div class="input-group">
                      <input class="touchspin-cart" type="number" value="1" />
                    </div>
                  </div>
                  <h5 class="cart-item-price">$2,999.00</h5>
                </div>
              </div>
            </li>
            <li class="dropdown-menu-footer">
              <div class="d-flex justify-content-between mb-1">
                <h6 class="fw-bolder mb-0">Total:</h6>
                <h6 class="text-primary fw-bolder mb-0">$10,999.00</h6>
              </div>
              <a
                class="btn btn-primary w-100"
                href="app-ecommerce-checkout.html"
                >Checkout</a
              >
            </li>
          </ul>
        </li> -->
        <!-- <li class="nav-item dropdown dropdown-notification me-25">
          <a class="nav-link" href="#" data-bs-toggle="dropdown"
            ><vue-feather class="ficon" type="bell"></vue-feather
            ><span class="badge rounded-pill bg-danger badge-up">5</span></a
          >
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 me-auto">Notifications</h4>
                <div class="badge rounded-pill badge-light-primary">6 New</div>
              </div>
            </li>
            <li class="scrollable-container media-list">
              <a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar">
                      <img src="../../../app-assets/images/portrait/small/avatar-s-15.jpg" alt="avatar" width="32" height="32">
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">Congratulation Sam 🎉</span
                      >winner!
                    </p>
                    <small class="notification-text">
                      Won the monthly best seller badge.</small
                    >
                  </div>
                </div> </a
              ><a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar">
                      <img src="../../../app-assets/images/portrait/small/avatar-s-3.jpg" alt="avatar" width="32" height="32">
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">New message</span>&nbsp;received
                    </p>
                    <small class="notification-text">
                      You have 10 unread messages</small
                    >
                  </div>
                </div> </a
              ><a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar bg-light-danger">
                      <div class="avatar-content">MD</div>
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">Revised Order 👋</span
                      >&nbsp;checkout
                    </p>
                    <small class="notification-text">
                      MD Inc. order updated</small
                    >
                  </div>
                </div>
              </a>
              <div class="list-item d-flex align-items-center">
                <h6 class="fw-bolder me-auto mb-0">System Notifications</h6>
                <div class="form-check form-check-primary form-switch">
                  <input
                    class="form-check-input"
                    id="systemNotification"
                    type="checkbox"
                    checked=""
                  />
                  <label
                    class="form-check-label"
                    for="systemNotification"
                  ></label>
                </div>
              </div>
              <a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar bg-light-danger">
                      <div class="avatar-content">
                        <i class="avatar-icon" data-feather="x"></i>
                      </div>
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">Server down</span>&nbsp;registered
                    </p>
                    <small class="notification-text">
                      USA Server is down due to high CPU usage</small
                    >
                  </div>
                </div> 
                </a><a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar bg-light-success">
                      <div class="avatar-content">
                        <i class="avatar-icon" data-feather="check"></i>
                      </div>
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">Sales report</span>&nbsp;generated
                    </p>
                    <small class="notification-text">
                      Last month sales report generated</small
                    >
                  </div>
                </div> </a
              ><a class="d-flex" href="#">
                <div class="list-item d-flex align-items-start">
                  <div class="me-1">
                    <div class="avatar bg-light-warning">
                      <div class="avatar-content">
                        <vue-feather
                          class="avatar-icon"
                          type="alert-triangle"
                        ></vue-feather>
                      </div>
                    </div>
                  </div>
                  <div class="list-item-body flex-grow-1">
                    <p class="media-heading">
                      <span class="fw-bolder">High memory</span>&nbsp;usage
                    </p>
                    <small class="notification-text">
                      BLR Server using high memory</small
                    >
                  </div>
                </div>
              </a>
            </li>
            <li class="dropdown-menu-footer">
              <a class="btn btn-primary w-100" href="#"
                >Read all notifications</a
              >
            </li>
          </ul>
        </li> -->
        <li class="nav-item dropdown dropdown-user">
          <a
            class="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="user-nav d-sm-flex d-none">
              <span class="user-name fw-bolder">{{ adminName }}</span
              ><span class="user-status">Admin</span>
            </div>
            <span class="avatar">
              <img
                class="round"
                :src="adminImage"
                alt="avatar"
                height="40"
                width="40" />
              <span class="avatar-status-online"></span
            ></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown-user"
          >
            <!-- <a class="dropdown-item" href="page-profile.html"
              ><vue-feather class="me-50" type="user"></vue-feather> Profile</a
            ><a class="dropdown-item" href="app-email.html"
              ><v class="me-50" type="mail"></v> Inbox</a
            ><a class="dropdown-item" href="app-todo.html"
              ><vue-feather
                class="me-50"
                vue-feather="check-square"
              ></vue-feather>
              Task</a
            ><a class="dropdown-item" href="app-chat.html"
              ><vue-feather class="me-50" type="message-square"></vue-feather>
              Chats</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="page-account-settings.html">
              <vue-feather class="me-50" type="settings"></vue-feather>
              Settings
            </a>
            <a class="dropdown-item" href="page-pricing.html"
              ><vue-feather class="me-50" type="credit-card"></vue-feather>
              Pricing</a
            ><a class="dropdown-item" href="page-faq.html"
              ><vue-feather
                class="me-50"
                vue-feather="help-circle"
              ></vue-feather>
              FAQ</a
            > -->
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              @click="logout"
              :disabled="disabled"
            >
              <vue-feather class="me-50" type="power"></vue-feather> Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import AdminProfile from "../../assets/images/avatar.jpg";
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      adminImage: AdminProfile,
      adminName: localStorage.getItem("auth_name"),
      disabled: false,
      token: localStorage.getItem("auth_token"),
    };
  },
  methods: {
    setToggleMenu() {
      this.$store.dispatch("setSidebarMenu", true);
    },
    async logout() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/logout", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.disabled = true;
            localStorage.clear();
            this.$router.go("/login");
            this.$toast.success(response.data.message);
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        });
    },
  },
};
</script>
