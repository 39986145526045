import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import VueFeather from 'vue-feather';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toaster from "@meforma/vue-toaster";
import store from './store'
// import CKEditor from '@ckeditor/ckeditor5-vue';
// import './assets/main.css'


const app = createApp(App);
app.use(store);
app.use(router);
app.component(VueFeather.name, VueFeather);
app.use(VueSweetalert2);
app.use(Toaster);
// app.use( CKEditor );
app.mount('#app');


export default createApp